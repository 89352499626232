
// eslint-disable-next-line no-unused-vars
import { PropType, computed, defineComponent } from 'vue'

interface AccountProps {
  image: string,
  connected: boolean
}

export default defineComponent({
  props: {
    accounts: {
      type: Object as PropType<AccountProps[]>,
      required: true
    },
  },
  setup (props) {
    const items = computed(() => {
      return props.accounts.map(account => ({
        icon: account.connected ? 'fa-check text-green-400' : '',
        logo: `background-image: url(${account.image})`,
        connected: account.connected
      }))
    })

    return {
      items
    }
  }
})
