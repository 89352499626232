<template>
  <button
    @click.prevent="openLoginPage"
    class="btn btn-primary"
  >
    {{ $t('action.continue') }}
    <i class="fas fa-spinner fa-spin !mr-0 ml-4" v-if="isLoading"></i>
    <i class="fas fa-arrow-right !mr-0 ml-4" v-else></i>
  </button>
</template>

<script lang="ts">
import { defineComponent, ref, unref } from 'vue'
import { firestore } from '@/plugins/firebase'
import { useProfile } from '@/composables/profile/useProfile'
import { useAxios } from '@/composables/useAxios'

export default defineComponent({
  props: {
    source: {
      type: String,
      required: true
    },
  },

  emits: [
    'connected',
  ],

  setup (props, { emit }) {
    const { profile } = useProfile()

    const isLoading = ref(false)
    
    const getLoginUrl = async (profileId: string) => {
      const { httpFunctions } = await useAxios()
      const { data } = await httpFunctions.get(`/socialLogin-requests/authorize/${profileId}/${props.source}`)
      
      if (props.source === 'facebook') {
        let url = new URL(data.url)
        url.searchParams.append('mc_time', ''+new Date().getTime())  
        return url.href
      }

      return data.url
    }

    const openLoginPage = async () => {
      isLoading.value = true
      const loginURL = await getLoginUrl(unref(profile).id)
      const loginWindow = window.open(loginURL, '_blank')

      // Um método de pooling para verificar regularmente se a janela ainda está aberta. Utilizar loginWindow.onbeforeunload não funciona por motivos de segurança do navegador, especialmente quando a janela aberta é de um domínio diferente
      if (loginWindow) {
        const checkLoginWindowClosed = setInterval(() => {
          if (loginWindow.closed) {
            clearInterval(checkLoginWindowClosed)
            isLoading.value = false
          }
        }, 1000)
      }

      const unsubscribe = firestore.collection('socialAccounts')
        .doc(unref(profile).id)
        .onSnapshot((accountsDoc) => {
          const accountsData = accountsDoc.data()

          if (accountsData && props.source in accountsData) {
            unsubscribe()

            loginWindow?.close()

            emit('connected')
            isLoading.value = false
          }
        })
    }

    return {
      openLoginPage,
      isLoading
    }
  }
})
</script>
