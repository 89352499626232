<template>
  <div class="card flex flex-row items-center justify-center gap-8 p-12">
    <div
    class="flex w-full flex-col items-center justify-center"
      v-for="(account, index) in items" :key="index"
    >
      <div
        class="bg-contain bg-center bg-no-repeat h-16 w-full"
        :style="account.logo"
      >
      </div>
      <div
        class="flex items-center justify-center mt-2 w-full"
        :class="{ 'text-gray-400': !account.connected  }"
      >
        <i class="fa mr-2" :class="account.icon"></i>
        {{ account.connected ? $t('sentence.connected') : $t('sentence.disconnected') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// eslint-disable-next-line no-unused-vars
import { PropType, computed, defineComponent } from 'vue'

interface AccountProps {
  image: string,
  connected: boolean
}

export default defineComponent({
  props: {
    accounts: {
      type: Object as PropType<AccountProps[]>,
      required: true
    },
  },
  setup (props) {
    const items = computed(() => {
      return props.accounts.map(account => ({
        icon: account.connected ? 'fa-check text-green-400' : '',
        logo: `background-image: url(${account.image})`,
        connected: account.connected
      }))
    })

    return {
      items
    }
  }
})
</script>
