
import { defineComponent, ref } from 'vue'
import { useProfile } from '@/composables/profile/useProfile'
import { useAxios } from '@/composables/useAxios'

export default defineComponent({
  props: {
    source: {
      type: String,
      required: true
    },
  },

  emits: [
    'disconnected',
  ],

  setup (props, {emit}) {
    const { profile } = useProfile()

    const isLoading = ref(false)

    const disconnectAccount = async () => {
      isLoading.value = true
      const { httpFunctions } = await useAxios()
      await httpFunctions.put(`/socialLogin-requests/disconnect/${profile.value.id}/${props.source}`)

      isLoading.value = false

      emit('disconnected')
    }

    return {
      disconnectAccount,
      isLoading
    }
  }
})
